import React, { useState } from "react";
import Modal from "./Modal"; // Import the Modal component

const Project = ({ image, name, text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const maxLength = 80; // Max length for truncation

  const displayText = isExpanded || text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-center items-center mb-0 md:mb-4">
      <div className="rounded-md shadow-md max-w-[550px] w-full h-[220px] flex bg-white">
        <div className="flex-col px-3 pt-2 flex-grow">
          <p className="text-md md:text-xl font-medium text-gray-700 text-left">{name}</p>
          <span className="text-left text-sm">{displayText}</span>
          {/* Show "Read More" button at the bottom */}
          {text.length > maxLength && (
            <div className="mt-auto">
              <button
                onClick={handleOpenModal}
                className="text-red-500 mt-2 focus:outline-none "
              >
                Үргэлжлүүлэх 
              </button>
            </div>
          )}
        </div>
        <img
          src={image}
          alt={name} // Better accessibility with meaningful alt text
          className="h-full object-cover object-center w-1/3"
        />
      </div>

      {/* Modal for displaying full text and larger image */}
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        image={image}
        name={name}
        text={text}
      />
    </div>
  );
};

export default Project;
