import React from "react";

const Contact = () => {
  return (
    <div className="w-full flex justify-center mb-16 mt-3">
      <div className="w-full max-w-[1200px] bg-white flex flex-col lg:flex-row lg:justify-between ">
        {/* Left Section */}
        <div className="w-full lg:w-1/2 xl:w-6/12 mb-8 lg:mb-0">
          <div className="max-w-[570px] m-8">
            <h1 className="mt-4 py-6 md:py-0 mb-6">Бидэнтэй холбогдох</h1>

            <p className="text-base text-body-color leading-relaxed mb-10"></p>
            <div className="mb-20">
              <div className="mt-2 flex items-center text-sm text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Info@SteelTech.mn
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                </svg>
                +(976) 88084259
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Улаанбаатар хот сүхбаатар дүүрэг бага тойруу
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Mon-Fri: 9AM-6PM
              </div>
            </div>
            <div className="h-64 w-full mb-4">
              <iframe
                title="mrgMap"
                className="w-full h-full border border-1 border-red-600"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2673.3531647059585!2d106.9155355764113!3d47.92955197122128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDU1JzQ2LjQiTiAxMDbCsDU1JzA1LjIiRQ!5e0!3m2!1smn!2smn!4v1719489867380!5m2!1smn!2smn"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/2 xl:w-6/12">
          <div className="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
            <form>
              <div className="mb-6">
                <input
                  type="text"
                  placeholder="Таны нэр"
                  className="w-full rounded py-3 px-[14px] text-body-color text-base border border-[#f0f0f0] outline-none focus-visible:shadow-none focus:border-primary"
                />
              </div>
              <div className="mb-6">
                <input
                  type="email"
                  placeholder="Таны цахим шуудан"
                  className="w-full rounded py-3 px-[14px] text-body-color text-base border border-[#f0f0f0] outline-none focus-visible:shadow-none focus:border-primary"
                />
              </div>
              <div className="mb-6">
                <input
                  type="text"
                  placeholder="Таны утас"
                  className="w-full rounded py-3 px-[14px] text-body-color text-base border border-[#f0f0f0] outline-none focus-visible:shadow-none focus:border-primary"
                />
              </div>
              <div className="mb-6">
                <textarea
                  rows="6"
                  placeholder="Таны мессеж"
                  className="w-full rounded py-3 px-[14px] text-body-color text-base border border-[#f0f0f0] resize-none outline-none focus-visible:shadow-none focus:border-primary"
                />
              </div>
              <div className=" flex justify-end">
                <button
                  type="submit"
                  className="mt-4 px-4 py-2 border-b-4 border-red-500 rounded-sm shadow-sm"
                >
                  Мессеж илгээх
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
