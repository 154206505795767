import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const SliderDetailed = ({ slides }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="max-h-screen"
      controls={false}
    >
      {slides.map((slide, idx) => (
        <Carousel.Item key={idx} interval={slide.interval}>
          <div className="flex justify-center h-[500px] mt-3 p-4">
            <div className="w-[1200px] flex justify-center">
              <div className="w-1/2 flex items-center justify-center bg-white">
                <div className="p-4">
                  <p className=" font-bold">Бүтээгдэхүүний Танилцуулга</p>
                  <p className="text-sm md:text-lg font-semibold text-neutral-700">
                    {slide.text}
                  </p>
                  <Link className="rounded-sm shadow-md py-2 px-2 border-b-4 border-red-500 no-underline text-black " to="/product">Бүтээгдэхүүнүүд</Link>
                </div>
              </div>
              <div className="w-1/2 flex items-center justify-center bg-white">
                <div className="max-w-[500px] max-h-[500px] overflow-hidden">
                  <img
                    src={slide.image}
                    alt={`Slide ${idx + 1}`}
                    className="object-cover w-full h-full"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default SliderDetailed;
