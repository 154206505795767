import React, { useState } from "react";
import terminal from "../assets/new/12.jpeg";
import cement from "../assets/project/IMG_3702.jpg";
import img1 from "../assets/new/1.jpeg";
import img2 from "../assets/new/2.jpeg";
import img3 from "../assets/new/3.jpeg";
import img4 from "../assets/new/4.jpeg";
import img5 from "../assets/new/5.jpeg";
import img6 from "../assets/new/6.jpeg";
import img7 from "../assets/new/7.jpeg";
import img8 from "../assets/new/8.jpeg";
import img9 from "../assets/new/9.jpeg";
import img10 from "../assets/new/10.jpeg";
import img11 from "../assets/new/11.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const News = () => {
  // Projects data with contentType
  const projects = [
    {
      name: "Stainless steel 304 / ган хоолой холбох хэрэгслийн техникийн үзүүлэлт",
      image: terminal,
      contentType: "table",
    },
    {
      name: "Монгол орны өндөр хайрханууд",
      image: cement,
      contentType: "slider",
      images: [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
      ],
    },
  ];

  // Modal state to handle "Read More" button click
  const [modalContent, setModalContent] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0); // Add currentSlide state

  const handleOpenModal = (project) => {
    setModalContent(project);
    if (project.contentType === "slider") {
      setCurrentSlide(0); // Reset to the first slide when opening the modal
    }
  };

  const handleCloseModal = (e) => {
    if (e.target === e.currentTarget) {
      setModalContent(null); // Close the modal
    }
  };

  // Render content based on contentType
  const renderModalContent = () => {
    if (!modalContent) return null;

    switch (modalContent.contentType) {
      case "table":
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="overflow-x-auto">
              <table className="min-w-full text-sm font-semibold">
                <thead>
                  <tr className="bg-blue-300">
                    <th
                      className="px-1 py-2.5 border text-center border-black px-2"
                      colSpan="2"
                    >
                      УНД АХУЙН УСНЫ НЕРЖ ХОЛБОХ ХЭРЭГСЭЛ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-black px-2 ">Хэмжээ</td>
                    <td className="border border-black px-2">
                      DN15mm-ээс DN108mm
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Материал</td>
                    <td className="border border-black px-2">
                      Stainless steel 304
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Өнгө</td>
                    <td className="border border-black px-2">Нерж / никель</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Онцлог шинж</td>
                    <td className="border border-black px-2">
                      <span className="text-green-500 font-bold">ЭКО</span>,
                      Байгалд ээлтэй бүтээгдхүүн
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Ажлын даралт</td>
                    <td className="border border-black px-2">
                      ≤1.6MPa - 16 bar
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">
                      Техникийн даралт
                    </td>
                    <td className="border border-black px-2">
                      ≤2.5MPa - 25 bar
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Ханын зузаан</td>
                    <td className="border border-black px-2">1.5mm - 2mm</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Сертификат</td>
                    <td className="border border-black px-2">
                      DVGW product / DAkkS, Deutsche Akkreditierungsstelle
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Стандарт</td>
                    <td className="border border-black px-2">
                      DIN, JIS, GB, ANSI
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Жийрэг резин</td>
                    <td className="border border-black px-2">
                      <span className="text-black font-bold">EPDM</span> black
                      , <span className="text-green-500 font-bold">FKM</span>
                       green ,
                      <span className="text-yellow-500 font-bold">NBR</span>
                       Yellow (High temperature)
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">
                      Ажилын температур
                    </td>
                    <td className="border border-black px-2">
                      Хэвийн-20℃ +110℃, Өндөр -20℃ +200℃
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Төрөл</td>
                    <td className="border border-black px-2">V type</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Тайлбар</td>
                    <td className="border border-black px-2">
                      Цэвэр усны систем, Хүнс үйлдвэрлэлийн шугам хоолойн
                      систем, Галын усны шугам хоолойн систем , хийн шугамын
                      системд тохиромжтой
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full text-sm font-semibold">
                <thead>
                  <tr className="bg-blue-300">
                    <th
                      className="py-2.5 border border-black px-2 text-center"
                      colSpan="2"
                    >
                      УНД АХУЙН УСНЫ НЕРЖ ГАН ХООЛОЙ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-black px-2">Хэмжээ</td>
                    <td className="border border-black px-2">
                      DN15mm-ээс DN108mm
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Материал</td>
                    <td className="border border-black px-2">
                      Stainless steel 304
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Өнгө</td>
                    <td className="border border-black px-2">Нерж / никель</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Онцлог шинж</td>
                    <td className="border border-black px-2">
                      <span className="text-green-500 font-bold">ЭКО</span>,
                      Байгалд ээлтэй бүтээгдхүүн
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Ажлын даралт</td>
                    <td className="border border-black px-2">≤2MPa - 20 bar</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">
                      Техникийн даралт
                    </td>
                    <td className="border border-black px-2">
                      ≤2.5MPa - 25 bar
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Ханын зузаан</td>
                    <td className="border border-black px-2">1.5mm - 2mm</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Сертификат</td>
                    <td className="border border-black px-2">
                      DVGW product / DAkkS, Deutsche Akkreditierungsstelle
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Стандарт</td>
                    <td className="border border-black px-2">
                      DIN, JIS, GB, ANSI
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">
                      Ажилын температур
                    </td>
                    <td className="border border-black px-2">
                      Хэвийн-20℃ +110℃, Өндөр -20℃ +200℃
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Төрөл</td>
                    <td className="border border-black px-2">V type</td>
                  </tr>
                  <tr>
                    <td className="border border-black px-2">Тайлбар</td>
                    <td className="border border-black px-2">
                      Цэвэр усны систем, Хүнс үйлдвэрлэлийн шугам хоолойн
                      систем, Галын усны шугам хоолойн систем , хийн шугамын
                      системд тохиромжтой
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      case "slider":
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ul className="mt-2 list-disc list-inside font-semibold">
              <li>Алтай таван богд, Хүйтэн оргил /4374м/</li>
              <li>Алтай таван богд, Цагаан суварга /4068м/</li>
              <li>Алтай таван богд, Бүргэд /4068м/</li>
              <li>Алтай таван богд, Наран /3870м/</li>
              <li>Мөнххайрхан уул, Сүхбаатар оргил /4362м/</li>
              <li>Мөнххайрхан уул, Барилгачин /4159м/</li>
              <li>Цамбагарав уул, Цаст оргил /4210м/</li>
              <li>Даривын нуруу, Сутай хайрхан /4230м/</li>
              <li>Хархираан уулс, Мөст оргил /4037м/</li>
              <li>Түргэний уулс, Дэглий цагаан /3965м/</li>
              <li>Түргэний уулс, Олимп оргил /3850м/</li>
            </ul>
            <div>
              <div className="relative w-72 lg:w-[540px] h-96 mx-auto">
                <img
                  src={modalContent.images[currentSlide]}
                  alt={`Slide ${currentSlide + 1}`}
                  className="w-auto h-full object-contain mx-auto"
                />
                <button
                  className="absolute top-1/2 left-0 transform -translate-y-1/2 p-3 rounded-full shadow-md hover:bg-gray-200 transition duration-200"
                  onClick={() =>
                    setCurrentSlide((prev) =>
                      prev === 0 ? modalContent.images.length - 1 : prev - 1
                    )
                  }
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  className="absolute top-1/2 right-0 transform -translate-y-1/2 p-3 rounded-full shadow-md hover:bg-gray-200 transition duration-200"
                  onClick={() =>
                    setCurrentSlide((prev) =>
                      prev === modalContent.images.length - 1 ? 0 : prev + 1
                    )
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full flex justify-center p-4">
      <div className="max-w-[1200px] w-full">
        <h1 className="mt-4 py-6 md:py-0 mb-6 text-2xl font-bold">Мэдээлэл</h1>

        {/* Container for projects */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => {
            const displayText = project.text; // Display the full text without truncation

            return (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <img
                  src={project.image}
                  alt={project.name}
                  className="w-full h-64 object-cover"
                />
                <div className="p-4">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {project.name}
                  </h2>
                  <p className="text-gray-600 font-semibold">{displayText}</p>
                  {/* Always show the button */}
                  <button
                    onClick={() => handleOpenModal(project)}
                    className="text-red-500 mt-2 focus:outline-none"
                  >
                    Үргэлжлүүлэх
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Modal for displaying full project details */}
      {modalContent && (
        <div
          className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleCloseModal} // Close modal when clicking on backdrop
        >
          <div
            className="bg-white p-6 rounded-md max-w-[90%] w-auto z-50"
            onClick={(e) => e.stopPropagation()} // Prevent clicks on modal content from closing the modal
          >
            <h2 className="text-lg font-bold mb-4">{modalContent.name}</h2>
            <p>{modalContent.text}</p>
            <div className="max-h-[400px] overflow-y-auto">
              {renderModalContent()}
            </div>
            <button onClick={handleCloseModal} className="mt-4 px-4 py-2 border-b-4 border-red-500 rounded-sm shadow-sm">
              Хаах
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
