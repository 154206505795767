import React from "react";
import Navbar from "../component/Navbar";
import logoImage from "../assets/logo.png";
import Logo from "../component/Logo";

const names = [
  "Бидний тухай",
  "Танилцуулга",
  "Бүтээгдэхүүн",
  "Мэдээлэл",
  "Холбогдох",
  "Үнийн санал татах" //changed pdf to Үнийн санал татах
];
const newurl = ["about", "introduction", "product", "news", "contact"];

const Header = () => {
  return (
    <div>
      <Navbar names={names} url={newurl} imageUrl={logoImage} />
      <Logo names={names} url={newurl} />
    </div>
  );
};

export default Header;
