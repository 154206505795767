import React from "react";
import Intro from "../component/Intro";
import image from "../assets/project/IMG_3702.jpg";

const Introduction = () => {
  const text = (
    <>
      <p className="mb-4 text-base font-semibold">
        Бид 2007 оноос эхлэн барилга орон сууц, уул уурхайн ба хүнд, хөнгөн үйлдвэрийн барилгын, төвийн болон бие даасан усан хангамж, ариутгах татуурга, халаалтын системийг угсарч суурьлуулан хийж гүйцэтгэж байна.
      </p>
      <p className="mb-4 text-base font-semibold">
        Орчин цагын барилгын салбар, усны инженерийн байгууламж, шугам сүлжээний хэрэгцээ шаардлагыг хангах, олон улсад хэрэглэгдэж буй STAINLESS STEEL 304 – 316 маркын чанарын баталгаатай ган шугам хоолойгоор, унд ахуйн усыг хэрэглэгчдэд зэвгүй бохирдолгүй хүргэхийг зорин ХБНГУ улсын стандартын зэвэрдэггүй ган хоолой, холбох хэрэгслийг үйлдвэрээс шууд импортлон, Дотоодын зах зээлд нэвтрүүлж байна.
      </p>
      <p className="mb-4 text-base font-semibold">
        Манай компани нь Монгол улсын барилгын салбар болоод захиалагч, хэрэглэгчиддээ олон улсын стандартад нийцсэн бараа бүтээгдхүүнээр ханган, дэвшилтэд технологиор үйлдвэрлэсэн материалыг нийлүүлэхээр зорин ажиллаж байна. Манай хамт олон сантехник угсралтын иж бүрэн үйлчилгээ үзүүлэх, салбартаа тэргүүлэгч компани болох зорилго тавин ажиллаж байна.
      </p>
      <p className="mb-4 flex justify-end font-semibold text-base">
        ХҮНДЭТГЭСЭН: STEEL TECH
      </p>
    </>
  );

  return (
    <div className="w-full">
      {/* Full-width banner image */}
      <div className="w-full">
        <img src={image} alt="Banner" className="w-full min-h-[300px] h-auto object-cover" />
      </div>

      {/* Content section with max-width */}
      <div className="w-full flex justify-center p-4">
        <div className="max-w-[1200px] w-full">
          <h1 className="mt-4 mb-6 text-2xl">Танилцуулга</h1> {/* Header font size */}
          <div className="text-justify"> {/* Justified text */}
            <Intro text={text} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
