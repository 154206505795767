import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa"; // Import arrow up icon from react-icons/fa

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when user scrolls down 100px
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Smooth scroll to top
    });
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="bg-neutral-200 hover:bg-customGray text-white py-2 px-2 rounded-full shadow-lg focus:outline-none"
        >
          <FaArrowUp className="h-6 w-6 text-customGray" />
        </button>
      )}
    </div>
  );
};

export default GoToTopButton;
