import React from "react";
import { useNavigate } from "react-router-dom";

const Intro = ({ topic, imageUrl, text }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact"); // Replace with the correct path to your About page
  };

  return (
    <div>
      <div className="mb-16">
        <div className="mt-8 lg:mt-4 mb-4">
          <img src={imageUrl} alt="" />
        </div>
        <p>{text}</p>
      </div>
      <div className="w-full py-7 flex justify-between px-7 rounded-lg shadow-lg mb-5 ">
        <div className="flex-col">
          <p>Хамтран ажиллах</p>
          <p>Хамтран ажиллахыг хүсвэл энэ дугаарлуу холбогдоно уу.</p>
        </div>
        <div className="flex-col items-end">
          <p>+976 88084259</p>
          <button
            onClick={handleButtonClick}
            className=" rounded-sm shadow-md px-2 py-2 border-b-4 border-red-500"
          >
            Энд дарна уу.
          </button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
