import image1 from "../assets/zurgnuud/1.png"
import image2 from "../assets/zurgnuud/2.png"
import image3 from "../assets/zurgnuud/3.png"
import image4 from "../assets/zurgnuud/4.png"
import image5 from "../assets/zurgnuud/5.png"
import image6 from "../assets/zurgnuud/6.png"
import image7 from "../assets/zurgnuud/7.png"
import image8 from "../assets/zurgnuud/8.png"
import image9 from "../assets/zurgnuud/9.png"
import image10 from "../assets/zurgnuud/10.png"
import image11 from "../assets/zurgnuud/11.png"
import image12 from "../assets/zurgnuud/12.png"
import image13 from "../assets/zurgnuud/13.png"
import image14 from "../assets/zurgnuud/14.png"
import image15 from "../assets/zurgnuud/15.png"
import image16 from "../assets/zurgnuud/16.png"
import image17 from "../assets/zurgnuud/17.png"
import image18 from "../assets/zurgnuud/18.png"
import image19 from "../assets/zurgnuud/19.png"
import image20 from "../assets/zurgnuud/20.png"
import image21 from "../assets/zurgnuud/21.png"
import image22 from "../assets/zurgnuud/22.jpg"
import image23 from "../assets/zurgnuud/23.jpg"
import image24 from "../assets/zurgnuud/24.png"
import image25 from "../assets/zurgnuud/25.jpg"
import image26 from "../assets/zurgnuud/26.jpg"
import image27 from "../assets/zurgnuud/27.jpg"
import image28 from "../assets/zurgnuud/28.jpg"
import image29 from "../assets/zurgnuud/29.jpg"
import image1a from "../assets/zurgnuud/1a.png"
import image2a from "../assets/zurgnuud/2a.png"
import image3a from "../assets/zurgnuud/3a.png"
import image4a from "../assets/zurgnuud/4a.png"
import image5a from "../assets/zurgnuud/5a.png"
import image6a from "../assets/zurgnuud/6a.png"
import image7a from "../assets/zurgnuud/7a.png"
import image8a from "../assets/zurgnuud/8a.png"
import image9a from "../assets/zurgnuud/9a.png"
import image10a from "../assets/zurgnuud/10a.png"
import image11a from "../assets/zurgnuud/11a.png"
import image12a from "../assets/zurgnuud/12a.png"
import image13a from "../assets/zurgnuud/13a.png"
import image14a from "../assets/zurgnuud/14a.png"
import image16a from "../assets/zurgnuud/16a.png"
import image17a from "../assets/zurgnuud/17a.png"
import image18a from "../assets/zurgnuud/18a.png"
import image19a from "../assets/zurgnuud/19a.png"
import image20a from "../assets/zurgnuud/20a.png"
import image21a from "../assets/zurgnuud/21a.png"

const data = [
  {
    id: 1,
    name: "Нерж хоолой",
    features: [
      {
        name: " Нерж хоолой",
        imageUrl: image24,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN15",
            L: 6000,
            K:"-",
            T: 1.5,
            price: 11800,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN22",
            L: 6000,
            K:"-",
            T: 1.5,
            price: 17000,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN28",
            L: 6000,
            K:"-",
            T: 1.5,
            price: 22100,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN35",
            L: 6000,
            K:"-",
            T: 1.5,
            price: 28800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN42",
            L: 6000,
            K:"-",
            T: 1.5,
            price: 35500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN54",
            L: 6000,
            K:"-",
            T: 1.5,
            price: 45500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN76",
            L: 6000,
            K:"-",
            T: 2.0,
            price: 68250,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN89",
            L: 6000,
            K:"-",
            T: 2.0,
            price: 82100,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN108",
            L: 6000,
            K:"-",
            T: 2.0,
            price: 97000,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Нерж холбох хэрэгсэл",
    features: [
      {
        name: "Пресс холбогч /Муфть/",
        imageUrl: image1,
        imageUrl1: image1a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN15",
            L: 50,
            K:"-",
            T: 1.5,
            price: 11200,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN22",
            L: 56,
            K:"-",
            T: 1.5,
            price: 13600,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN28",
            L: 58,
            K:"-",
            T: 1.5,
            price: 19900,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN35",
            L: 64,
            K:"-",
            T: 1.5,
            price: 29100,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN42",
            L: 84,
            K:"-",
            T: 1.5,
            price: 34200,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN54",
            L: 92,
            K:"-",
            T: 1.5,
            price: 39800,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN76",
            L: 151,
            K:"-",
            T: 2.0,
            price: 64300,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN89",
            L: 161,
            K:"-",
            T: 2.0,
            price: 77800,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN108",
            L: 181,
            K:"-",
            T: 2.0,
            price: 96300,
          },
        ],
      },
      {
        name: "Пресс гүүдэг холбогч /Гүүдэг муфть/",
        imageUrl: image2,
        imageUrl1: image2a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN15",
            L: 80,
            K:"-",
            T: 1.5,
            price: 12900,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN22",
            L: 85,
            K:"-",
            T: 1.5,
            price: 17200,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN28",
            L: 96,
            K:"-",
            T: 1.5,
            price: 25600,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN35",
            L: 106,
            K:"-",
            T: 1.5,
            price: 32900,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN42",
            L: 121,
            K:"-",
            T: 1.5,
            price: 37600,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN54",
            L: 136,
            K:"-",
            T: 1.5,
            price: 43100,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN76",
            L: 230,
            K:"-",
            T: 2.0,
            price: 79150,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN89",
            L: 258,
            K:"-",
            T: 2.0,
            price: 93500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn:"DN108",
            L: 305,
            K:"-",
            T: 2.0,
            price: 119250,
          },
        ],
      },
      {
        name: "2 үзүүрт пресс шилжвэр",
        imageUrl: image3,
        imageUrl1: image3a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×15",
            L:71,
            K:30,
            T:1.5,
            price: 13800,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28x15",
            L:79,
            K:30,
            T:1.5,
            price: 17500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×22",
            L:60,
            K:30,
            T:1.5,
            price: 18200,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35x15",
            L:72,
            K:38,
            T:1.5,
            price: 23200,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×22",
            L:70,
            K:38,
            T:1.5,
            price: 25500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×28",
            L:67,
            K:38,
            T:1.5,
            price: 27200,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42x15",
            L:80,
            K:44,
            T:1.5,
            price: 29200,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×22",
            L:78,
            K:44,
            T:1.5,
            price: 33500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×28",
            L:82,
            K:44,
            T:1.5,
            price: 36500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×35",
            L:80,
            K:44,
            T:1.5,
            price: 38900,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54x15",
            L:75,
            K:50,
            T:1.5,
            price: 50500,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54x22",
            L:97,
            K:50,
            T:1.5,
            price: 52500,
          },
          {
            id: 13,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×28",
            L:92,
            K:50,
            T:1.5,
            price: 54500,
          },
          {
            id: 14,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×35",
            L:95,
            K:50,
            T:1.5,
            price: 58500,
          },
          {
            id: 15,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×42",
            L:101,
            K:50,
            T:1.5,
            price: 65500,
          },
          {
            id: 16,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×54",
            L:140,
            K:56,
            T:2.0,
            price: 82500,
          },
          {
            id: 17,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×54",
            L:156,
            K:62,
            T:2.0,
            price: 94000,
          },
          {
            id: 18,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×76.1",
            L:156,
            K:62,
            T:2.0,
            price: 96800,
          },
          {
            id: 19,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×54",
            L:204,
            K:70,
            T:2.0,
            price: 121000,
          },
          {
            id: 20,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×76.1",
            L:196,
            K:70,
            T:2.0,
            price: 128000,
          },
          {
            id: 21,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×88.9",
            L:190,
            K:70,
            T:2.0,
            price: 135000,
          },
        ],
      },
      {
        name: "Энгийн үзүүрт пресс шилжвэр",
        imageUrl: image4,
        imageUrl1: image4a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×15",
            L:64,
            K:24,
            T:1.5,
            price: 13800,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28x15",
            L:72,
            K:26,
            T:1.5,
            price: 17800,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×22",
            L:63,
            K:29,
            T:1.5,
            price: 17500,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35x15",
            L:75,
            K:29,
            T:1.5,
            price: 23200,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×22",
            L:73,
            K:29,
            T:1.5,
            price: 25500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×28",
            L:60,
            K:29,
            T:1.5,
            price: 27200,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42x15",
            L:90,
            K:38,
            T:1.5,
            price: 29200,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×22",
            L:87,
            K:38,
            T:1.5,
            price: 33500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×28",
            L:84,
            K:38,
            T:1.5,
            price: 36500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×35",
            L:77,
            K:38,
            T:1.5,
            price: 38900,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54x15",
            L:100,
            K:42,
            T:1.5,
            price: 50500,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54x22",
            L:97,
            K:42,
            T:1.5,
            price: 52500,
          },
          {
            id: 13,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×28",
            L:94,
            K:42,
            T:1.5,
            price: 54500,
          },
          {
            id: 14,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×35",
            L:96,
            K:42,
            T:1.5,
            price: 58500,
          },
          {
            id: 15,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×42",
            L:101,
            K:42,
            T:1.5,
            price: 65500,
          },
          {
            id: 16,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76×54",
            L:140,
            K:52,
            T:2.0,
            price: 82500,
          },
          {
            id: 17,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN89×54",
            L:156,
            K:64,
            T:2.0,
            price: 94000,
          },
          {
            id: 18,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN89×76",
            L:156,
            K:64,
            T:2.0,
            price: 96800,
          },
          {
            id: 19,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×54",
            L:204,
            K:75,
            T:2.0,
            price: 121000,
          },
          {
            id: 20,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×76",
            L:196,
            K:75,
            T:2.0,
            price: 128000,
          },
          {
            id: 21,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×89",
            L:100,
            K:75,
            T:2.0,
            price: 135000,
          },
        ],
      },
      {
        name: "90° пресс булан",
        imageUrl: image5,
        imageUrl1: image5a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:43,
            K:43,
            T:1.5,
            price: 13500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:52,
            K:52,
            T:1.5,
            price: 16000,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:62,
            K:62,
            T:1.5,
            price: 23000,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:74,
            K:74,
            T:1.5,
            price: 31500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:93,
            K:93,
            T:1.5,
            price: 48900,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:110,
            K:110,
            T:1.5,
            price: 62500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1",
            L:189,
            K:189,
            T:2.0,
            price: 115000,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9",
            L:212,
            K:212,
            T:2.0,
            price: 141500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:257,
            K:257,
            T:2.0,
            price: 188800,
          },
        ],
      },
      {
        name: "90° энгийн үзүүрт пресс булан",
        imageUrl :image6,
        imageUrl1: image6a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:43,
            K:42,
            T:1.5,
            price: 13500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:52,
            K:52,
            T:1.5,
            price: 16000,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:62,
            K:60,
            T:1.5,
            price: 23000,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:74,
            K:70,
            T:1.5,
            price: 31500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:93,
            K:89,
            T:1.5,
            price: 48900,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:110,
            K:107,
            T:1.5,
            price: 62500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76",
            L:150,
            K:146,
            T:2.0,
            price: 115000,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN89",
            L:174,
            K:171,
            T:2.0,
            price: 141500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:215,
            K:211,
            T:2.0,
            price: 188800,
          },
        ],
      },
      {
        name: "45° пресс булан",
        imageUrl: image7,
        imageUrl1: image7a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:32,
            K:32,
            T:1.5,
            price: 13500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:36,
            K:36,
            T:1.5,
            price: 16000,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:41,
            K:41,
            T:1.5,
            price: 23000,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:48,
            K:48,
            T:1.5,
            price: 31500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:61,
            K:61,
            T:1.5,
            price: 48900,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:71,
            K:71,
            T:1.5,
            price: 62500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1",
            L:128,
            K:128,
            T:2.0,
            price: 115000,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN89",
            L:139,
            K:139,
            T:2.0,
            price: 141500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:178,
            K:178,
            T:2.0,
            price: 188800,
          },
        ],
      },
      {
        name: "45° энгийн үзүүрт пресс булан",
        imageUrl: image8,
        imageUrl1: image8a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:32,
            K:32,
            T:1.5,
            price: 13500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:36,
            K:36,
            T:1.5,
            price: 16000,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:41,
            K:41,
            T:1.5,
            price: 23000,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:48,
            K:48,
            T:1.5,
            price: 31500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:61,
            K:61,
            T:1.5,
            price: 48900,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:71,
            K:71,
            T:1.5,
            price: 62500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1",
            L:128,
            K:128,
            T:2.0,
            price: 115000,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN89",
            L:139,
            K:139,
            T:2.0,
            price: 141500,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:178,
            K:178,
            T:2.0,
            price: 188800,
          },
        ],
      },
      {
        name: "Пресс гуравлагч",
        imageUrl: image9,
        imageUrl1: image9a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:87,
            K:41,
            T:1.5,
            price: 15900,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:90,
            K:45,
            T:1.5,
            price: 18300,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:96,
            K:48,
            T:1.5,
            price: 27900,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:106,
            K:53,
            T:1.5,
            price: 44500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:130,
            K:65,
            T:1.5,
            price: 56500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:150,
            K:75,
            T:1.5,
            price: 78200,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1",
            L:250,
            K:125,
            T:2.0,
            price: 115000,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN89",
            L:270,
            K:135,
            T:2.0,
            price: 145000,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:300,
            K:150,
            T:2.0,
            price: 198800,
          },
        ],
      },
      {
        name: "Пресс шилжвэртэй гуравлагч",
        imageUrl: image10,
        imageUrl1: image10a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×15",
            L:90,
            K:43.5,
            T:1.5,
            price: 21900,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28x15",
            L:96,
            K:46.5,
            T:1.5,
            price: 33500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×22",
            L:96,
            K:45.5,
            T:1.5,
            price: 33500,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35x15",
            L:106,
            K:47.5,
            T:1.5,
            price: 46800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×22",
            L:106,
            K:49,
            T:1.5,
            price: 48800,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×28",
            L:106,
            K:50,
            T:1.5,
            price: 49200,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42x15",
            L:130,
            K:52.5,
            T:1.5,
            price: 52500,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×22",
            L:130,
            K:53.5,
            T:1.5,
            price: 53000,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×28",
            L:130,
            K:54.5,
            T:1.5,
            price: 54500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×35",
            L:130,
            K:58.5,
            T:1.5,
            price: 55500,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54x15",
            L:150,
            K:58.5,
            T:1.5,
            price: 56500,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×22",
            L:150,
            K:59.5,
            T:1.5,
            price: 59000,
          },
          {
            id: 13,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×28",
            L:150,
            K:60.5,
            T:1.5,
            price: 60500,
          },
          {
            id: 14,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×35",
            L:150,
            K:64.5,
            T:1.5,
            price: 62500,
          },
          {
            id: 15,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×42",
            L:150,
            K:73.5,
            T:1.5,
            price: 65000,
          },
          {
            id: 16,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×22",
            L:250,
            K:70.5,
            T:1.5,
            price: 85500,
          },
          {
            id: 17,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×28",
            L:250,
            K:71.5,
            T:2.0,
            price: 89000,
          },
          {
            id: 18,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×35",
            L:250,
            K:75.5,
            T:2.0,
            price: 96800,
          },
          {
            id: 19,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×42",
            L:250,
            K:84.5,
            T:2.0,
            price: 105800,
          },
          {
            id: 20,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×54",
            L:250,
            K:88.5,
            T:2.0,
            price: 112000,
          },
          {
            id: 21,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×22",
            L:270,
            K:77,
            T:2.0,
            price: 123000,
          },
          {
            id: 22,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×28",
            L:270,
            K:78,
            T:2.0,
            price: 135000,
          },
          {
            id: 23,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×35",
            L:270,
            K:82,
            T:2.0,
            price: 142000,
          },
          {
            id: 24,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×42",
            L:270,
            K:91,
            T:2.0,
            price: 156500,
          },
          {
            id: 25,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×54",
            L:270,
            K:95,
            T:2.0,
            price: 160000,
          },
          {
            id: 26,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×76.1",
            L:270,
            K:124,
            T:2.0,
            price: 162500,
          },
          {
            id: 27,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×22",
            L:300,
            K:86.5,
            T:2.0,
            price: 172000,
          },
          {
            id: 28,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×28",
            L:300,
            K:87.5,
            T:2.0,
            price: 178000,
          },
          {
            id: 29,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×35",
            L:300,
            K:91.5,
            T:2.0,
            price: 182500,
          },
          {
            id: 30,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×42",
            L:300,
            K:95.5,
            T:2.0,
            price: 185500,
          },
          {
            id: 31,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×54",
            L:300,
            K:99.5,
            T:2.0,
            price: 192500,
          },
          {
            id: 32,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×76.1",
            L:300,
            K:133.5,
            T:2.0,
            price: 198800,
          },
          {
            id: 33,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×88.9",
            L:300,
            K:138.5,
            T:2.0,
            price: 198800,
          },
        ],
      },
      {
        name: "Пресс гуравлагч эр үзүүр",
        imageUrl: image11,
        imageUrl1: image11a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×R1/2",
            L:82,
            K:40.5,
            T:1.5,
            price: 18800,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×R1/2",
            L:90,
            K:44,
            T:1.5,
            price: 25800,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×R3/4",
            L:90,
            K:44,
            T:1.5,
            price: 26800,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×R1",
            L:90,
            K:44,
            T:1.5,
            price: 26800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×R1/2",
            L:96,
            K:48,
            T:1.5,
            price: 31200,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×R3/4",
            L:96,
            K:48,
            T:1.5,
            price: 33500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×R1",
            L:96,
            K:48,
            T:1.5,
            price: 33500,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×R 1/4",
            L:106,
            K:52,
            T:1.5,
            price: 39800,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×R1 3/4",
            L:106,
            K:52,
            T:1.5,
            price: 42500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×R1/4",
            L:130,
            K:72,
            T:1.5,
            price: 50500,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×R1 1/2",
            L:130,
            K:72,
            T:1.5,
            price: 54500,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×R3/4",
            L:150,
            K:85,
            T:1.5,
            price: 64500,
          },
          {
            id: 13,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×R2",
            L:150,
            K:92.6,
            T:1.5,
            price: 72500,
          },
        ],
      },
      {
        name: "Пресс гуравлагч эм үзүүр",
        imageUrl: image12,
        imageUrl1: image12a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×Rp1/2",
            L:82,
            K:33,
            T:1.5,
            price: 18800,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp1/2",
            L:90,
            K:37,
            T:1.5,
            price: 25800,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp3/4",
            L:90,
            K:41,
            T:1.5,
            price: 26800,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×Rp1/2",
            L:96,
            K:40,
            T:1.5,
            price: 26800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×Rp3/4",
            L:96,
            K:44,
            T:1.5,
            price: 31200,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×Rp1",
            L:96,
            K:45,
            T:1.5,
            price: 33500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×Rp1/2",
            L:106,
            K:43,
            T:1.5,
            price: 33500,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×Rp3/4",
            L:106,
            K:42,
            T:1.5,
            price: 39800,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×Rp1/2",
            L:130,
            K:57,
            T:1.5,
            price: 42500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×Rp3/4",
            L:130,
            K:56,
            T:1.5,
            price: 50500,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×Rp1/2",
            L:150,
            K:61,
            T:1.5,
            price: 54500,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×Rp3/4",
            L:150,
            K:60,
            T:1.5,
            price: 64500,
          },
          {
            id: 13,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×Rp2",
            L:150,
            K:68,
            T:1.5,
            price: 72500,
          },
          {
            id: 14,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×Rp3/4",
            L:250,
            K:72,
            T:1.5,
            price: 115000,
          },
          {
            id: 15,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×Rp2",
            L:250,
            K:79,
            T:1.5,
            price: 126500,
          },
          {
            id: 16,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×Rp3/4",
            L:270,
            K:85,
            T:1.5,
            price: 135600,
          },
          {
            id: 17,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×Rp2",
            L:270,
            K:96,
            T:1.5,
            price: 152500,
          },
          {
            id: 18,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×Rp3/4",
            L:300,
            K:102,
            T:1.5,
            price: 172500,
          },
          {
            id: 19,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108×Rp2",
            L:300,
            K:108,
            T:1.5,
            price: 192500,
          },
        ],
      },
      {
        name: "90° пресс булан эр үзүүр",
        imageUrl: image13,
        imageUrl1: image13a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×R1/2",
            L:43,
            K:48,
            T:1.5,
            price: 18500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×R1/2",
            L:50,
            K:59,
            T:1.5,
            price: 21500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×R1",
            L:62,
            K:80,
            T:1.5,
            price: 24500,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×R1 1/4",
            L:74,
            K:94,
            T:1.5,
            price: 45800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×R1 1/2",
            L:93,
            K:108,
            T:1.5,
            price: 76500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54XR1/2", 
            L:96,
            K:116,
            T:1.5,
            price: 79500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×R2",
            L:110,
            K:128,
            T:1.5,
            price: 82500,
          },
        ],
      },
      {
        name: "90° пресс булан эм үзүүр",
        imageUrl: image14,
        imageUrl1: image14a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×Rp1/2",
            L:43,
            K:40,
            T:1.5,
            price: 18500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22xRp1/2",
            L:52,
            K:52,
            T:1.5,
            price: 21500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp3/4",
            L:52,
            K:57,
            T:1.5,
            price: 24500,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×Rp1",
            L:62,
            K:62,
            T:1.5,
            price: 45800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×Rp1 1/4",
            L:74,
            K:75,
            T:1.5,
            price: 76500,
          },
        ],
      },
      {
        name: "90° Пресс чихтэй булан",
        imageUrl: image15,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×Rp1/2",
            L:46,
            K:26,
            T:1.5,
            price: 15900,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp1/2",
            L:49,
            K:26,
            T:1.5,
            price: 17800,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp3/4",
            L:58,
            K:27,
            T:1.5,
            price: 19200,
          },
        ],
      },
      {
        name: "Пресс холбох планз",
        imageUrl: image16,
        imageUrl1: image16a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:55,
            K:95,
            T:1.5,
            price: 75500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:59,
            K:105,
            T:1.5,
            price: 92500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:65,
            K:115,
            T:1.5,
            price: 110000,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:69,
            K:140,
            T:1.5,
            price: 170000,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:77,
            K:150,
            T:1.5,
            price: 190000,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:87,
            K:165,
            T:1.5,
            price: 245000,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1",
            L:126,
            K:185,
            T:2.0,
            price: 295000,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9",
            L:147,
            K:200,
            T:2.0,
            price: 345000,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:167,
            K:220,
            T:2.0,
            price: 425000,
          },
        ],
      },
      {
        name: "Пресс эр үзүүр",
        imageUrl: image17,
        imageUrl1: image17a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×R1/2",
            L:52,
            K:"-",
            T:1.5,
            price: 8900,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×R3/4",
            L:55,
            K:"-",
            T:1.5,
            price: 9200,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×R1/2",
            L:52,
            K:"-",
            T:1.5,
            price: 12300,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×R3/4",
            L:55,
            K:"-",
            T:1.5,
            price: 14500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×R3/4",
            L:58,
            K:"-",
            T:1.5,
            price: 21200,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×R1",
            L:62,
            K:"-",
            T:1.5,
            price: 21200,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×R1",
            L:65,
            K:"-",
            T:1.5,
            price: 28300,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×R1 1/4",
            L:69,
            K:"-",
            T:1.5,
            price: 28300,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×R1 1/4",
            L:94,
            K:"-",
            T:1.5,
            price: 35500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×R1 1/2",
            L:82,
            K:"-",
            T:1.5,
            price: 36800,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×R1 1/2",
            L:86,
            K:"-",
            T:1.5,
            price: 47800,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×R2",
            L:105,
            K:"-",
            T:1.5,
            price: 49900,
          },
          {
            id: 13,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1×R2 1/2",
            L:125,
            K:"-",
            T:2.0,
            price: 168800,
          },
          {
            id: 14,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9×R3",
            L:135,
            K:"-",
            T:2.0,
            price: 182500,
          },
        ],
      },
      {
        name: "Пресс эм үзүүр",
        imageUrl: image18,
        imageUrl1: image18a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×Rp1/2",
            L:47,
            K:"-",
            T:1.5,
            price: 8900,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×Rp3/4",
            L:49,
            K:"-",
            T:1.5,
            price: 9200,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp1/2",
            L:49,
            K:"-",
            T:1.5,
            price: 12300,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22×Rp3/4",
            L:50,
            K:"-",
            T:1.5,
            price: 14500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×Rp3/4",
            L:50,
            K:"-",
            T:1.5,
            price: 21200,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28×Rp1",
            L:58,
            K:"-",
            T:1.5,
            price: 21200,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×Rp1",
            L:62,
            K:"-",
            T:1.5,
            price: 28300,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35×Rp1 1/4",
            L:64,
            K:"-",
            T:1.5,
            price: 28300,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×Rp1 1/4",
            L:83,
            K:"-",
            T:1.5,
            price: 35500,
          },
          {
            id: 10,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42×Rp1 1/2",
            L:77,
            K:"-",
            T:1.5,
            price: 36800,
          },
          {
            id: 11,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×Rp1 1/2",
            L:88,
            K:"-",
            T:1.5,
            price: 47800,
          },
          {
            id: 12,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×Rp2",
            L:104,
            K:"-",
            T:1.5,
            price: 49900,
          },
        ],
      },
      {
        name: "Пресс холбогч эм үзүүртэй эргэдэг жийрэгтэй",
        imageUrl: image19,
        imageUrl1: image19a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×G1/2",
            L:65,
            K:"-",
            T:1.5,
            price: 13400,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15×G3/4",
            L:68,
            K:"-",
            T:1.5,
            price: 14000,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22xG3/4",
            L:70,
            K:"-",
            T:1.5,
            price: 17900,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28xG1",
            L:75.5,
            K:"-",
            T:1.5,
            price: 24500,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35xG11/4",
            L:83,
            K:"-",
            T:1.5,
            price: 32500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42xG11/2",
            L:92,
            K:"-",
            T:1.5,
            price: 43500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54×G2",
            L:104,
            K:"-",
            T:1.5,
            price: 56500,
          },
        ],
      },
      {
        name: "Пресс таг",
        imageUrl: image20,
        imageUrl1: image20a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:33,
            K:"-",
            T:1.5,
            price: 5500,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:38,
            K:"-",
            T:1.5,
            price: 6500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:39,
            K:"-",
            T:1.5,
            price: 7800,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:44,
            K:"-",
            T:1.5,
            price: 8800,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:53,
            K:"-",
            T:1.5,
            price: 10500,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:60,
            K:"-",
            T:1.5,
            price: 13500,
          },
          {
            id: 7,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN76.1",
            L:101,
            K:"-",
            T:2.0,
            price: 65500,
          },
          {
            id: 8,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN88.9",
            L:109,
            K:"-",
            T:2.0,
            price: 75800,
          },
          {
            id: 9,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN108",
            L:133,
            K:"-",
            T:2.0,
            price: 93500,
          },
        ],
      },
      {
        name: "Гүүр хоолой",
        imageUrl: image21,
        imageUrl1: image21a,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:117,
            K:44,
            T:1.5,
            price: 10200,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:128,
            K:53,
            T:1.5,
            price: 14200,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:142,
            K:64,
            T:1.5,
            price: 19800,
          },
        ],
      },
      {
        name: "Пресс хаалт",
        imageUrl: image22,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:43,
            K:"-",
            T:1.5,
            price: 43800,
          },
          {
            id: 2,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN22",
            L:56,
            K:"-",
            T:1.5,
            price: 72500,
          },
          {
            id: 3,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28",
            L:65,
            K:"-",
            T:1.5,
            price: 91800,
          },
          {
            id: 4,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN35",
            L:79,
            K:"-",
            T:1.5,
            price: 157000,
          },
          {
            id: 5,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN42",
            L:85,
            K:"-",
            T:1.5,
            price: 192000,
          },
          {
            id: 6,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN54",
            L:108,
            K:"-",
            T:1.5,
            price: 230000,
          },
        ],
      },
      {
        name: "Булангын хаалт /Аварын/",
        imageUrl: image23,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN15",
            L:"-",
            K:"-",
            T:"-",
            price: 23500,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Багаж хэрэгсэл",
    features: [
      {
        name: "Пресс толгой",
        imageUrl: image26,
        specifications: [
          { 
            id: 1,
            standart: "RIDGID",
            dn: "DN15",
            L:"-",
            K:"-",
            T:"-",
            price: 345000,
          },
          {
            id: 2,
            standart: "RIDGID",
            dn: "DN22",
            L:"-",
            K:"-",
            T:"-",
            price: 345000,
          },
          {
            id: 3,
            standart: "RIDGID",
            dn: "DN28",
            L:"-",
            K:"-",
            T:"-",
            price: 390000,
          },
          {
            id: 4,
            standart: "RIDGID",
            dn: "DN35",
            L:"-",
            K:"-",
            T:"-",
            price: 410000,
          },
          {
            id: 5,
            standart: "RIDGID",
            dn: "DN42",
            L:"-",
            K:"-",
            T:"-",
            price: 497000,
          },
          {
            id: 6,
            standart: "RIDGID",
            dn: "DN54",
            L:"-",
            K:"-",
            T:"-",
            price: 553000,
          },
        ],
      },
      {
        name: "Пресс багаж /Толгойгүй/",
        imageUrl: image27,
        specifications: [
          { 
            id: 1,
            model: "RP-306",
            standart: "RIDGID",
            dn: "395х159х77",
            L:"-",
            K:"-",
            T:"-",
            price: "-",
            morespec : [
              {
                dn:"DN15-DN108",
                power:"3.266кг",
                weight:"3.0кг",
                lenght:"395мм",
                height:"159мм",
                width:"77мм",
                battery:"18v 2.5ah",
                time:"7сек",
              }
            ]
          },
        ],
      },
      {
        name: "Турба тасдагч",
        imageUrl: image28,
        specifications: [
          { 
            id: 1,
            standart: "RIDGID",
            dn: "DN15 - DN28",
            L:"-",
            K:"-",
            T:"-",
            price: 297000,
          },
          {
            id: 2,
            standart: "RIDGID",
            dn: "DN35 - DN54",
            L:"-",
            K:"-",
            T:"-",
            price: 382000,
          },
        ],
      },
      {
        name: "Ирмэг дарагч",
        imageUrl: image29,
        specifications: [
          { 
            id: 1,
            standart: "RIDGID",
            dn: "DN15 - DN28",
            L:55,
            K:45,
            T:"-",
            price: 240000,
          },
          {
            id: 2,
            standart: "RIDGID",
            dn: "DN35 - DN54",
            L:90,
            K:70,
            T:"-",
            price: 368000,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Ус цэвэршүүлэгч",
    features: [
      {
        name: "Feature 1",
        image : "C:/Users/emurd/Desktop/steel-tech/src/assets/zurgnuud",
        specifications: [
          { 
            id: 0,
            standart: "-",
            dn: "-",
            L:"-",
            K:"-",
            T:"-",
            price: 0,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "Алчуур хатаагч",
    features: [
      {
        name: "Алчуур хатаагч",
        imageUrl: image25,
        specifications: [
          { 
            id: 1,
            standart: "STAINLESS STEEL-304 EN-10312",
            dn: "DN28/600x600",
            L:"-",
            K:"-",
            T:"-",
            price: 375000,
          },
        ],
      },
    ],
  },
];

export default data;
