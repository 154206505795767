import React from "react";
import Slider from "../component/Slider";
import "bootstrap/dist/css/bootstrap.min.css";
import Image1 from "../assets/01.jpg";
import Image2 from "../assets/02.jpg";
import Image3 from "../assets/03.jpg";
import Image11 from "../assets/1.jpg";
import Image22 from "../assets/2.jpg";
import Image33 from "../assets/4.jpg";
import SliderDetailed from "../component/SliderDetailed";

const slides = [
  { image: Image1, interval: 2500 },
  { image: Image2, interval: 2500 },
  { image: Image3, interval: 2500 },
];

const slides2 = [
  {
    image: Image11,
    text: "RIDGID Брендын гар тасдагч нь stainless steel 304 DN15-DN54 хүртэлэх шугам хоолойг гар аргаар хялбар таслахад зориулагдсан багаж.",
    interval: 2500,
  },
  {
    image: Image22,
    text: "RIDGID Брендын ирмэг дарагч нь stainless steel 304 DN15-DN54 хүртэлэх шугам хоолойн тасдагдсан ирмэгийг гар аргаар хялбар тэгшилж цэвэрлэх үүрэгтэй багаж.",
    interval: 2500,
  },
  {
    image: Image33,
    text: "RIDGID Брендын пресс багаж нь steinless steel 304 DN15 - DN 108 хүртэлэх шугам хоолойг, холбох хэрэгсэлтэй 3263кг-ын өндөр даралтаар хялбар холбоход зориулагдсан багаж.",
    interval: 2500,
  },
];

const Home = () => {
  return (
    <div>
      <Slider slides={slides} />
      <SliderDetailed slides={slides2} />
    </div>
  );
};

export default Home;
