import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/navbarlogo.png";

const Logo = ({ names, url }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  let lastScrollY = window.scrollY;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const scrollDistance = 10; // Adjust this value as needed

    if (currentScrollY > lastScrollY && currentScrollY - lastScrollY > scrollDistance) {
      // Scroll down
      setIsVisible(false);
    } else if (currentScrollY < lastScrollY && lastScrollY - currentScrollY > scrollDistance) {
      // Scroll up
      setIsVisible(true);
    }

    lastScrollY = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Run effect only once

  const openPDFViewer = () => {
    // Change this URL to the path of your PDF
    window.open("/Үнийн санал.pdf", "_blank");
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-[50px] lg:h-[90px] flex justify-between items-center z-10 lg:relative lg:justify-center lg:mt-11 md:relative md:mt-11 bg-neutral-200 shadow-md transition-transform duration-300 ${
        isVisible ? "transform translate-y-0" : "transform -translate-y-full"
      }`}
    >
      <div className="w-[1200px]">
        <div className="flex items-center h-full">
          <Link
            to="/"
            onClick={closeMenu}
            className="flex items-center text-white hover:text-white py-1 text-sm font-medium no-underline"
          >
            <img src={logo} alt="Home" className="h-10 md:h-[90px]" />
          </Link>
        </div>
      </div>
      <div className="flex lg:hidden md:hidden p-2">
        {/* Menu icon for small screens */}
        <svg
          onClick={toggleMenu}
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-black cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </div>

      {/* Responsive menu */}
      <div
        className={`lg:hidden absolute top-[50px] left-0 w-full bg-customGray text-white transition-all duration-300 ease-in-out ${
          menuOpen
            ? "opacity-100 max-h-[300px] py-2" // Fully visible
            : "opacity-0 max-h-0 overflow-hidden" // Hidden with animation
        }`}
      >
        <div className="w-[80%] mx-auto">
          {names.map((name, index) => {
            if (name === "Үнийн санал татах") {
              return (
                <button
                  key={index}
                  onClick={openPDFViewer}
                  className="block py-2 px-4 text-sm font-medium text-white no-underline"
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </button>
              );
            } else {
              return (
                <Link
                  key={index}
                  to={`/${url[index]}`}
                  className="block py-2 px-4 text-sm font-medium text-white no-underline"
                  onClick={closeMenu} // Close menu on link click
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </Link>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Logo;
