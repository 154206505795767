import React from "react";

const Modal = ({ isOpen, onClose, image, name, text }) => {
  if (!isOpen) return null;

  // Handles closing the modal when clicking outside of the content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOverlayClick} // Call the handler when clicking on the background
    >
      <div className="bg-white p-6 rounded-lg max-w-[600px] w-full">
        <img src={image} alt={name} className="w-full h-auto object-cover mb-4" />
        <h2 className="text-xl font-bold mb-2">{name}</h2>
        <p>{text}</p>
        <button
          onClick={onClose} // Close the modal when clicking the close button
          className="mt-4 px-4 py-2 border-b-4 border-red-500 rounded-sm shadow-sm"
        >
          Хаах
        </button>
      </div>
    </div>
  );
};

export default Modal;
