import React from "react";
import Project from "../component/Project";
import terminal from "../assets/project/1.jpg";
import block from "../assets/project/2.jpg";
import venti from "../assets/project/3.jpg";
import cement from "../assets/project/4.jpg";
import image1 from "../assets/project/305651313_568498235061776_2902010523020155470_n.jpg";
import image2 from "../assets/project/ХУВЦАС 'ХАНУЙ ХАНГАЙ' ХХК лого.jpg";

const About = () => {
  const projects = [
    {
      name: "Шивээ Хүрэн боомтын ТЕРМИНАЛ",
      image: terminal,
      text: "Өмнөговь аймаг Гурван Тэс сум, Улсын хилийн Шивээ Хүрэн боомтын ТЕРМИНАЛ-ын барилгын дотор сантехник / дотор дулаан хангамж, усан хангамж ариутгах татуурга/, гадна шугам хоолой угсралт суурилуулалтын ажлыг хийж гүйцэтгэв.",
    },
    {
      name: "МАК Евро Цемент үйлдвэр",
      image: cement,
      text: "“Монголын Алт” (МАК) ХХК -ны Дорноговь аймгийн Даланжаргалан сумын нутагт байрлах “МАК Евро цемент” үйлдвэрийн дотор сантехникийн системийн угсралт суурилуулалтын ажлыг хийж гүйцэтгэв.",
    },
    {
      name: "МАК Евро Венти үйлдвэр",
      image: venti,
      text: "“Монголын Алт” (МАК) ХХК -ний Төв аймгийн Сэргэлэн сумын нутагт байрлах “МАК Евро хаалга, цонх, салхивчны” үйлдвэрийн дотор сантехникийн системийн угсралт суурилуулалтын ажлыг хийж гүйцэтгэв.",
    },
    {
      name: "МАК Евро Блок үйлдвэр",
      image: block,
      text: "“Монголын Алт” (МАК) ХХК -ний Төв аймаг Сэргэлэн сумын нутагт байрлах МАК Евро Блокийн үйлдвэрийн дотор сантехникийн системийн угсралт суурилуулалтын ажлыг хийж гүйцэтгэв.",
    },
    // Add more projects as needed
  ];

  return (
    <div className="w-full flex justify-center p-4">
      <div className="max-w-[1200px] w-full">
        <h1 className="mt-4 py-6 md:py-0 mb-6">Хийсэн төслүүд</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="mb-10">
              <Project
                name={project.name}
                image={project.image}
                text={project.text}
              />
            </div>
          ))}
        </div>

        <div className="w-full py-7 flex flex-col md:flex-row items-center justify-between px-4 md:px-7 rounded-lg shadow-lg mb-5">
          <div className="text-lg font-semibold">
            Хамтран ажилладаг байгууллагууд
          </div>
          <div className="flex flex-wrap justify-center md:justify-end">
            <img
              src={image1}
              alt="Partner 1"
              className="h-20 object-cover mx-2 mb-2 md:mb-0"
            />
            <img
              src={image2}
              alt="Partner 2"
              className="h-20 object-cover mx-2 mb-2 md:mb-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
