import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ names, url, imageUrl }) => {
  const location = useLocation();

  const openPDFViewer = () => {
    window.open("/Үнийн санал.pdf", "_blank"); // Open the PDF in a new tab
  };

  return (
    <nav
      className="fixed top-0 left-0 z-50 shadow-md w-full h-11 bg-cover bg-center bg-no-repeat hidden md:flex"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="w-full flex justify-center">
        <div className="w-[1200px] flex items-center justify-between">
          <div className="ml-auto flex">
            {names.map((name, index) => {
              // Check if the name is "Үнийн санал татах" to trigger the PDF viewer function
              if (name === "Үнийн санал татах") {
                return (
                  <button
                    key={index}
                    onClick={openPDFViewer}
                    className="text-white hover:text-white px-3 py-2.5 text-sm font-medium no-underline"
                  >
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </button>
                );
              } else {
                return (
                  <Link
                    key={index}
                    to={`/${url[index]}`}
                    className={`text-white hover:text-white px-3 py-2.5 text-sm font-medium no-underline ${
                      location.pathname === `/${url[index]}`
                        ? "border-b-4 border-red-500"
                        : ""
                    }`}
                  >
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
