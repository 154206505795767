import React from "react";
import Products from "../component/Product";

const ProductPage = () => {
  return (
    <div className="w-full flex justify-center h-full p-4">
      <div className="max-w-[1200px] w-full">
        <h1 className=" mt-4 py-6 md:py-0 mb-6">Бүтээгдэхүүний төрөл</h1>
        <div className="mt-3">
          <Products />
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
